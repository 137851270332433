import { getCurrentInstance } from 'vue';
import { type Router } from 'vue-router';

export function useRouter(): Router {
    const instance = getCurrentInstance();
    const router = instance?.proxy?.$router;

    if (!router) {
        throw new Error('No router is configured');
    }

    return router;
}
